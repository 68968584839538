<!-- <app-spinner *ngIf="loadingTypes$ | async" [scrennCenter]="true"></app-spinner> -->

<mat-dialog-header
    [title]="'Регистрация'"
    [ngClass]="{ 'account-type-selected': accountType != 0 }"
    [titleHidden]="accountType != 0"
>
</mat-dialog-header>

<mat-dialog-content [ngClass]="{ 'account-type-selected': accountType != 0 }">
    <section class="account-type-selector" *ngIf="accountType == 0">
        <mat-card class="example-card" (click)="accountType = 1">
            <mat-card-header>
                <mat-card-title>Стандарт</mat-card-title>
                <mat-card-subtitle>Для личного использования</mat-card-subtitle>
            </mat-card-header>
        </mat-card>
        <mat-card class="card-dark" (click)="accountType = 2">
            <mat-card-header>
                <mat-card-title>PRO</mat-card-title>
                <mat-card-subtitle> Для работы и бизнеса </mat-card-subtitle>
            </mat-card-header>
        </mat-card>
    </section>

    <section class="register-form" *ngIf="accountType == 1">
        <mat-card class="example-card">
            <mat-card-header>
                <mat-card-title>Стандарт</mat-card-title>
                <!-- <mat-card-subtitle>Для личного использования</mat-card-subtitle> -->
            </mat-card-header>
            <mat-card-content>
                <section class="cart-form-columns">
                    <ng-container
                        *ngTemplateOutlet="formFieldsBase; context: ctx"
                    >
                    </ng-container>
                </section>
                <section class="cart-actions">
                    <button
                        mat-raised-button
                        color="primary"
                        size="xl"
                        class="large"
                        (click)="onSubmit()"
                        [disabled]="!newUser.contractors_is_rights_granted || registerForm.invalid"
                    >
                        Зарегистрироваться
                    </button>
                </section>
            </mat-card-content>
        </mat-card>
    </section>

    <section class="register-form" *ngIf="accountType == 2">
        <mat-card class="card-dark">
            <mat-card-header>
                <mat-card-title>PRO</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <section class="cart-form-columns">
                    <ng-container
                        *ngTemplateOutlet="formFieldsBase; context: ctx"
                    >
                    </ng-container>
                    <ng-container>
                        <ng-container
                            *ngTemplateOutlet="formFieldsAdvance; context: ctx"
                        >
                        </ng-container>
                    </ng-container>
                </section>
                <section class="cart-actions">
                    <button
                        mat-raised-button
                        color="primary"
                        size="xl"
                        class="large"
                        (click)="onSubmit()"
                        [disabled]="!newUser.contractors_is_rights_granted || registerForm.invalid || registerFormAdvance.invalid"
                    >
                        Зарегистрироваться
                    </button>
                </section>
            </mat-card-content>
        </mat-card>
    </section>

    <!-- <form [formGroup]="edit.form" (ngSubmit)="onAddEditDeviceClick()">
        <app-input imusRequired [label]="'Имя'" formControlName="name" [required]="true"></app-input>
        <field-errors formFieldName="name"></field-errors>
        <div class="separator"></div>
        <app-select-search imusRequired formControlName="type" [label]="'Тип'" [options]="deviceTypeList"
            [required]="true"    [objectKeyProperty]="'id'"></app-select-search>
        <field-errors formFieldName="type"></field-errors>
        <div class="separator"></div>
        <app-input imusRequired [label]="'Долгота'" [type]="inputType.number" formControlName="lon" [required]="true"></app-input>
        <field-errors formFieldName="lon"></field-errors>
        <div class="separator"></div>
        <app-input imusRequired [label]="'Широта'" [type]="inputType.number" formControlName="lat" [required]="true"></app-input>
        <field-errors formFieldName="lat"></field-errors>
        <div class="separator"></div>
        <app-input [label]="'Мин'" [type]="inputType.number" formControlName="min"></app-input>
        <div class="separator"></div>
        <app-input [label]="'Макс'" [type]="inputType.number" formControlName="max"></app-input>
        <div class="separator"></div>
        <app-text-area [label]="'Описание'" formControlName="notes"></app-text-area>
    </form> -->
</mat-dialog-content>

<!-- <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close [disabled]="!isValid" (click)="onAddEditDeviceClick()">{{actionName}}</button>
    <button mat-button mat-dialog-close>Отменить</button>
</mat-dialog-actions> -->

<ng-template #formFieldsBase>
    <form [formGroup]="registerForm">
        <mat-form-field appearance="outline">
            <mat-label>Фамилия</mat-label>
            <input
                [(ngModel)]="newUser.contractor_persons_name_last"
                formControlName="contractor_persons_name_last"
                placeholder="Введите вашу фамилию"
                matInput
            />
            <mat-icon matPrefix>man</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Имя</mat-label>
            <input
                [(ngModel)]="newUser.contractor_persons_name_first"
                formControlName="contractor_persons_name_first"
                placeholder="Введите ваше имя"
                matInput
            />
            <mat-icon matPrefix>man</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Отчество</mat-label>
            <input
                [(ngModel)]="newUser.contractor_persons_name_second"
                formControlName="contractor_persons_name_second"
                placeholder="Введите ваше отчество"
                matInput
            />
            <mat-icon matPrefix>man</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input
                [(ngModel)]="newUser.user_email"
                type="email"
                [email]="true"
                formControlName="user_email"
                placeholder="Укажите электронную почту"
                matInput
            />
            <mat-icon matPrefix>email</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Телефон</mat-label>
            <input
                [(ngModel)]="newUser.user_phone"
                formControlName="user_phone"
                placeholder="Укажите номер телефона"
                prefix="+7"
                mask="(000) 000 00 00"
                matInput
            />
            <mat-icon matPrefix>phone</mat-icon>
        </mat-form-field>

        <!-- <div formGroupName="passGroup">
            <p>Введите новый пароль</p>

            <app-input
                class="input-cell"
                [type]="inputType.password"
                imusRequired
                [placeholder]="'Новый пароль'"
                formControlName="password"
            ></app-input>
            <field-errors formFieldName="password">
                <field-error-label
                    rule="minlength"
                    message="Минимальная длина 8 символов"
                ></field-error-label>
            </field-errors>

            <div class="separator"></div>

            <app-input
                class="input-cell"
                [type]="inputType.password"
                imusRequired
                [placeholder]="'Повторите новый пароль'"
                formControlName="confirmPassword"
            ></app-input>
            <field-errors formFieldName="confirmPassword">
                <field-error-label
                    rule="minlength"
                    message="Минимальная длина 8 символов"
                ></field-error-label>
            </field-errors>
        </div> -->

        <div formGroupName="passGroup">
            <mat-form-field appearance="outline">
                <mat-label>Пароль</mat-label>
                <input
                    [(ngModel)]="newUser.user_password"
                    formControlName="password"
                    [type]="displayPassword ? 'text' : 'password'"
                    placeholder="Введите пароль"
                    matInput
                />
                <mat-icon matPrefix>key</mat-icon>
                <button
                    mat-icon-button
                    style="position: absolute; right: 0; top: 4px"
                    (click)="
                        $event.preventDefault();
                        displayPassword = !displayPassword
                    "
                >
                    <!-- <mat-icon *ngIf="!displayPassword">visibility</mat-icon>
                    <mat-icon *ngIf="displayPassword">visibility_off</mat-icon> -->
                    <!-- *ngIf="displayPassword" -->
                    <mat-icon>{{displayPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error 
                    *ngIf="this.registerForm.controls.passGroup.controls.password.errors?.minlength"
                    >Минимум 8 символов
                </mat-error>
                <mat-error 
                    *ngIf="this.registerForm.controls.passGroup.controls.password.errors?.required"
                    >Заполните поле
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Проверка пароля</mat-label>
                <input
                    formControlName="confirmPassword"
                    [type]="displayTwoPassword ? 'text' : 'password'"
                    placeholder="Повторите пароль"
                    matInput
                />
                <mat-icon matPrefix>key</mat-icon>
                <button
                    mat-icon-button
                    style="position: absolute; right: 0; top: 4px"
                    (click)="
                        $event.preventDefault();
                        displayTwoPassword = !displayTwoPassword
                    "
                >
                    <mat-icon *ngIf="!displayTwoPassword">visibility</mat-icon>
                    <mat-icon *ngIf="displayTwoPassword"
                        >visibility_off</mat-icon
                    >
                </button>
                <!-- style="color: red" -->
                <!-- *ngIf="
                registerForm.value.passGroup.confirmPassword !=
                registerForm.value.passGroup.password
                " -->
                <mat-error
                    *ngIf="this.registerForm.controls.passGroup.controls.confirmPassword.errors?.notSame"
                    >Пароли не совпадают
                </mat-error>
                <mat-error 
                    *ngIf="this.registerForm.controls.passGroup.controls.confirmPassword.errors?.minlength"
                    >Минимум 8 символов
                </mat-error>
                <mat-error 
                    *ngIf="this.registerForm.controls.passGroup.controls.confirmPassword.errors?.required"
                    >Заполните поле
                </mat-error>
            </mat-form-field>
        </div>
        <!-- <mat-form-field> -->
        
        <mat-error 
            *ngIf="this.registerForm.errors?.notUniqueEmail"
            >Пользователь с таким email уже зарегистрирован
        </mat-error>
        <mat-checkbox
            [(ngModel)]="newUser.contractors_is_rights_granted"
            [ngModelOptions]="{ standalone: true }"
            >Согласие на обработку персональных данных</mat-checkbox
        >
        <!-- </mat-form-field> -->
    </form>
</ng-template>

<ng-template #formFieldsAdvance>
    <!-- [ngClass]="{'type': accountProType == 1}" -->
    <form
        class="fields-pro type-{{ accountProType }}"
        [formGroup]="registerFormAdvance"
    >
        <h5>Сведения об организации</h5>

        <mat-radio-group
            [(ngModel)]="accountProType"
            (change)="accountProTypeSelectionChange($event)"
            [ngModelOptions]="{ standalone: true }"
        >
            <mat-radio-button
                *ngFor="let typeItem of accountProTypes"
                [checked]="typeItem.value == accountProType"
                [value]="typeItem.value"
                >{{ typeItem.name }}</mat-radio-button
            >
        </mat-radio-group>

        <!-- <mat-form-field appearance="outline">
            <mat-label>ЮрЛицо/ИП</mat-label>
            <input
                [(ngModel)]="newUser.urFirst"
                formControlName="urFirst"
                placeholder="ЮрЛицо/ИП"
                matInput
            />
            <mat-icon matPrefix>man</mat-icon>
        </mat-form-field> -->

        <!-- *ngIf="accountProType == 1" -->
        <mat-form-field appearance="outline" *ngIf="accountProType == 1">
            <mat-label>Наименование</mat-label>
            <input
                [(ngModel)]="newUser.contractor_descr"
                formControlName="contractor_descr"
                placeholder="Наименование"
                matInput
            />
            <mat-icon matPrefix>man</mat-icon>
        </mat-form-field>
        <div class="empty-cell" *ngIf="accountProType == 2">
        </div>

        <mat-form-field appearance="outline">
            <mat-label>ИНН</mat-label>
            <input
                [(ngModel)]="newUser.contractor_requisites_inn"
                formControlName="contractor_requisites_inn"
                placeholder="ИНН"
                matInput
            />
            <!-- [mask]="accountProType == 1 ? '0000000000' : '000000000000'" -->
            <mat-icon matPrefix>list</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="accountProType == 1">
            <mat-label>КПП</mat-label>
            <input
                [(ngModel)]="newUser.contractor_requisites_kpp"
                formControlName="contractor_requisites_kpp"
                placeholder="КПП"
                mask="000000000"
                matInput
            />
            <mat-icon matPrefix>list</mat-icon>
        </mat-form-field>
        <div class="empty-cell" *ngIf="accountProType == 2">
        </div>

        <mat-form-field class="form-field-address" appearance="outline">
            <mat-label>Юридический адрес</mat-label>
            <textarea
                matInput
                [(ngModel)]="newUser.contractor_address"
                placeholder="Юридический адрес"
                formControlName="contractor_address"
                cdkTextareaAutosize
                cdkAutosizeMinRows="6"
            ></textarea>
            <!-- <input
                [(ngModel)]="newUser.urAddress"
                formControlName="urAddress"
                placeholder="Юридический адрес"
                matInput
            /> -->
            <mat-icon matPrefix>home</mat-icon>
        </mat-form-field>
    </form>
</ng-template>
