
<form [formGroup]="edit.form" (ngSubmit)="onEditProjectClick()">
   <app-input imusRequired [label]="'Имя'" formControlName="name" [outline]="true" [required]="true"></app-input>
    <field-errors formFieldName="name"></field-errors>
    <div class="separator"></div>
    <h3>Исполнитель</h3>
    <app-input imusRequired [label]="'Компания'" formControlName="contractorCompany" [required]="true"></app-input>
    <field-errors formFieldName="contractorCompany"></field-errors>
    <div class="separator"></div>
    <app-input imusRequired [label]="'Контактное лицо'" formControlName="contractorRepresentative" [required]="true"></app-input>
    <field-errors formFieldName="contractorRepresentative"></field-errors>
    <div class="separator"></div>
    <app-input imusRequired [label]="'E-mail'" [type]="inputType.email" formControlName="contractorEmail" [required]="true"></app-input>
    <field-errors formFieldName="contractorEmail"></field-errors>
    <div class="separator"></div>
    <app-input imusRequired [label]="'Телефон'" [type]="inputType.tel" formControlName="contractorPhone" [required]="true"></app-input>
    <field-errors formFieldName="contractorPhone"></field-errors>
    <div class="separator"></div>
    <app-input imusRequired [label]="'Город'" formControlName="contractorCity" [required]="true"></app-input>
    <field-errors formFieldName="contractorCity"></field-errors>
    <div class="separator"></div>
    <app-input imusRequired [label]="'Индекс'" [type]="inputType.number" formControlName="contractorZip" [required]="true"></app-input>
    <field-errors formFieldName="contractorZip"></field-errors>
    <div class="separator"></div>
    <app-input imusRequired [label]="'Улица'" formControlName="contractorStreet" [required]="true"></app-input>
    <field-errors formFieldName="contractorStreet"></field-errors>
    <div class="separator"></div>
    <app-input imusRequired [label]="'Web'" formControlName="contractorWeb" [required]="true"></app-input>
    <field-errors formFieldName="contractorWeb"></field-errors>
    <div class="separator"></div>
    <app-text-area imusRequired [label]="'Описание'" formControlName="contractorNotes" [required]="true"></app-text-area>
    <field-errors formFieldName="contractorNotes"></field-errors>
    <div class="separator"></div>
    <h3>Заказчик</h3>
    <app-input imusRequired [label]="'Компания'" formControlName="clientCompany" [required]="true"></app-input>
    <field-errors formFieldName="clientCompany"></field-errors>
    <div class="separator"></div>
    <app-input imusRequired [label]="'Контактное лицо'" formControlName="clientRepresentative" [required]="true"></app-input>
    <field-errors formFieldName="clientRepresentative"></field-errors>
    <div class="separator"></div>
    <app-input imusRequired [label]="'E-mail'" [type]="inputType.email" formControlName="clientEmail" [required]="true"></app-input>
    <field-errors formFieldName="clientEmail"></field-errors>
    <div class="separator"></div>
    <app-input imusRequired [label]="'Телефон'" [type]="inputType.tel" formControlName="clientPhone" [required]="true"></app-input>
    <field-errors formFieldName="clientPhone"></field-errors>
    <div class="separator"></div>
    <app-input imusRequired [label]="'Город'" formControlName="clientCity" [required]="true"></app-input>
    <field-errors formFieldName="clientCity"></field-errors>
    <div class="separator"></div>
    <app-input imusRequired [label]="'Индекс'" [type]="inputType.number" formControlName="clientZip" [required]="true"></app-input>
    <field-errors formFieldName="clientZip"></field-errors>
    <div class="separator"></div>
    <app-input imusRequired [label]="'Улица'" formControlName="clientStreet" [required]="true"></app-input>
    <field-errors formFieldName="clientStreet"></field-errors>
    <div class="separator"></div>
    <app-input imusRequired [label]="'Web'" formControlName="clientWeb" [required]="true"></app-input>
    <field-errors formFieldName="clientWeb"></field-errors>
    <div class="separator"></div>
    <app-text-area imusRequired [label]="'Описание'" formControlName="clientNotes" [required]="true"></app-text-area>
    <field-errors formFieldName="clientNotes"></field-errors>
    <div class="separator"></div>
</form>


<section class="panel-action">
    <!-- mat-dialog-close -->
    <button mat-raised-button color="primary" [disabled]="!isValid" (click)="onEditProjectClick()">Изменить</button>
    <button mat-raised-button color="danger" (click)="onCancelClick()">Отменить</button>
</section>