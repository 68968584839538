import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { animationFrameScheduler, observeOn, ReplaySubject, shareReplay, tap } from "rxjs";
import { ImusDestroyService } from "@services/destroy.service";
import { ControlContainer, FormBuilder, FormGroup, FormGroupDirective } from "@angular/forms";
import { NodeRef, NodeType } from "@shared/models";
import { COORDINATES_MASK_SEPARATED_OPTIONS } from "@shared/validators/coordinates.validator";
import { CodeCategoryService } from "@services/code-category.service";
import { CodeCategory } from "@shared/models/code-category";
import { LayerService, LayersStateService, NodeService } from "@shared/services";
import { map, takeUntil } from "rxjs/operators";
import {
    editPanelForm
} from "@app/modules/main/modules/details-info-panel/components/details-edit-panel/details-edit-panel-form.models";
import { EditingFieldNameProvider } from "@app/modules/main/modules/details-info-panel/editing-field-name.provider";
import { OPERATIONS_STATE } from "@app/modules/main/modules/details-info-panel/operations-states";

type EventType = 'focusout' | 'keydown.enter'

@Component({
    selector: 'app-details-node-view',
    templateUrl: './details-node.view.component.html',
    styleUrls: ['./details-node.view.component.scss'],
    providers: [ImusDestroyService],
    viewProviders: [
        {
            provide: ControlContainer,
            useExisting: FormGroupDirective
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailsNodeViewComponent {
    private readonly _node$ = new ReplaySubject<NodeRef>(1);
    public readonly node$ = this._node$;

    public readonly codeCategory = CodeCategory;
    public readonly current$ = this.editingFieldName.current$.pipe(
        observeOn(animationFrameScheduler)
    );

    public coordinatesMask = COORDINATES_MASK_SEPARATED_OPTIONS;
    public operationsList$ = this._node$.pipe(
        map(item => [
            { key: false, value: OPERATIONS_STATE.get(item.nodeType.id).off },
            { key: true, value: OPERATIONS_STATE.get(item.nodeType.id).on }
        ]),
        shareReplay(1)
    );

    public event: EventType

    @Input()
    set item(value: NodeRef) {
        this._node$.next(value);
    }

    @Input()
    form: FormGroup;

    @Input()
    isLayerGlobal: boolean = false;

    @Output()
    fieldValueChange = new EventEmitter<editPanelForm.EntityFieldEventType<NodeRef>>();

    public readonly nodeTypes$ = this.nodeService.nodeTypes$.pipe(
        shareReplay({ refCount: true, bufferSize: 1 })
    )

    public editableLayer$ = this._node$.pipe(
        map(node => this.layersStateService.state.getLayerById(node.layer)),
        shareReplay({ refCount: true, bufferSize: 1 })
    );
    public nodeTypes: NodeType[]

    constructor(
        private readonly _fb: FormBuilder,
        private readonly _destroy$: ImusDestroyService,
        public readonly categoryList: CodeCategoryService,
        private readonly nodeService: NodeService,
        public readonly editingFieldName: EditingFieldNameProvider,
        public readonly layersStateService: LayersStateService,
        public readonly layerService: LayerService,
    ) {
        this.nodeService.nodeTypes$.pipe(
            tap(nodeTypes => this.nodeTypes = nodeTypes),
            takeUntil(this._destroy$)
        )
            .subscribe()
    }

    public operationsValue(item: NodeRef): string {
        return OPERATIONS_STATE.get(item.nodeType.id)[!!item.on ? 'on' : 'off'];
    }

    public editField(item: NodeRef, value: unknown, fieldName: string): void {
        if (value === item[fieldName]) {
            return;
        }
        if (fieldName === 'node_type_id') {
            item.nodeType = this.nodeTypes.find(nodeType => nodeType.id == (value as number))
            // {
            //     id: value as number,
            //     name: value === 1 ? 'well' : 'connection',
            //     layer_type_id: item.nodeType.layer_type_id
            // }
        } else {
            item[fieldName] = value;
        }
        this.fieldValueChange.emit({
            value, item, fieldName
        })
    }

    // setEvent(event: EventType) {
    //     this.event = event
    //     // console.log('setEvent', event)
    // }

    public getNodeTypes(item: NodeRef): NodeType[] {
        return this.nodeTypes?.filter(nodeType => nodeType.layer_type_id == item.nodeType.layer_type_id) //LayerTypeIDs[layerSubtype])
    }
}
