// todo rolemodel
export class User {
    id: number;
    name: string;
    phone: string;
    email: string;
    mapCenter: string = '';
    language: string = '';
    token: string;
    created_at: string;
    email_verified_at: string;
    start_lon: string;
    start_lat: string;
    start_zoom: number;
    u_data: {};
}

export interface UserAccountsFeature {
    success: boolean;
    data: UserAccountsPage | Credentials[];
    message: string;
}

export interface UserAccountsPage {
    current_page: number;
    data: UserAccountsInfo[];
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    links: {
        url: string;
        label: string;
        active: boolean;
    }[],
    next_page_url: string;
    path: string;
    per_page: number;
    prev_page_url: string;
    to: number;
    total: number;
}

export interface UserAccountsInfo {
    id: number;
    user_id: number;
    contractor_id: number;
    role_id: number;
    start_date: string;
    end_date: string;
    is_primary: boolean;
    created_at: string;
    updated_at: string;
    is_main: true,
    user_name: string;
    user_email: string;
    user_email_verified_at: string;
    user_phone: string;
    start_lon: string;
    start_lat: string;
    start_zoom: string;
    account_type_id: number;
    account_type_descr: string;
    account_type_name: string;
    account_type_seq: number;
    role_descr: string;
    role_name: string;
    contractor_type_id: number;
    contractor_type_descr: string;
    contractor_type_name: string;
    contractor_descr: string;
    contractor_inn: number;
    contractor_kpp: number;
    formatted: [];
    status_descr?: string;
}

export interface Credentials {
    can_create: boolean;
    can_delete: boolean;
    can_read: boolean;
    can_update: boolean;
    id: number;
    role_id: number;
    subject_id: number;
}

export interface ContractorsAccess {
    success: boolean;
    data: ContractorsAccessData[]
}

export interface ContractorsAccessData {
    id: number;
    role_id: number;
    subject_id: number;
    can_create: boolean;
    can_read: boolean;
    can_update: boolean;
    can_delete: boolean;
}

export interface AccountsView {
    success: boolean;
    data: UserAccountsPage | AccountsViewData[];
}

export interface AccountsViewData {
    id: number;
    user_id: number;
    contractor_id: number;
    role_id: number;
    start_date: string;
    end_date: string;
    is_primary: boolean;
    created_at: string;
    updated_at: string;
    is_main: boolean;
    user_name: string;
    user_email: string;
    user_phone: string;
    account_type_descr: string;
    account_type_name: string;
    account_type_seq: string;
    role_descr: string;
    role_name: string;
    contractor_type_id: number;
    contractor_type_descr: string;
    contractor_type_name: string;
    contractor_descr: string;
    job_post: string;
    job_post_gen: string;
    job_post_dative: unknown;
    is_signer: boolean;
    is_proxy: boolean;
    proxy_doc_number: number;
    proxy_doc_date: string;
    contractor_inn: string;
    contractor_kpp: string;
    contractor_address: string;
    formatted: []
}

export interface UserContractorsReq {
    /** Идентификатор аккаунта */
    id: number;
    /** Идентификатор типа контрагента */
    type_id: number;
    phone?: number;
    email?: string;
    site?: string;
    status_id?: number;
    address?: string;
    /** Соглашение */
    is_rights_granted: boolean;
    requisite_short_name?: string;
    requisite_full_name?: string;
    requisite_inn?: number;
    requisite_kpp?: number;
    requisite_ogrn?: number;
    requisite_ogrnip?: number;
    requisite_director_full_name?: string;
    requisite_director_phone?: number;
    requisite_director_post?: number;
    person_name_last?: number;
    person_name_first?: number;
    person_name_second?: number;
    person_birthday?: number;
    person_birthplace?: number;
    person_name_gen?: number;
    person_name_dative?: number;
    person_sex_type_id?: number;
    idcard_type_id?: number;
    idcard_doc_num?: number;
    idcard_doc_date?: string;
    idcard_doc_org?: string;
    bank_descr?: string;
    bank_bik?: number;
    bank_swift?: string;
    bank_inn?: number;
    bank_kpp?: number;
    bank_okpo?: number;
    bank_account?: number;
    bank_contractor_account?: number;
}

export interface ContractorsView {
    success: boolean;
    data: {
        data: ContractorsViewData[];
    };
}

export interface ContractorsViewData {
    id: number;
    type_id: number;
    descr: string;
    phone: string;
    email: string;
    site: string;
    address: string;
    is_rights_granted: boolean;
    status_id: number;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    verified_at: string;
    type_descr: string;
    type_name: string;
    status_descr: string;
    status_name: string;
    requisite_short_name: string;
    requisite_full_name: string;
    requisite_inn: string;
    requisite_kpp: string;
    requisite_ogrn: string;
    requisite_ogrnip: string;
    person_name_last: string;
    person_name_first: string;
    person_name_second: string;
    person_birthday: string;
    person_birthplace: string;
    person_name_gen: string;
    person_name_dative: string;
    person_sex_type_id: number;
    person_sex_type_descr: string;
    idcard_type_id: number;
    idcard_type_descr: string;
    idcard_doc_num: string;
    idcard_doc_date: string;
    idcard_doc_org: string;
    bank_descr: string;
    bank_bik: string;
    bank_swift: string;
    bank_inn: string;
    bank_kpp: string;
    bank_okpo: string;
    bank_account: string;
    bank_contractor_account: string;
    requisite_director_full_name: string;
    requisite_director_phone: string;
    requisite_director_post: string;
    formatted: {
        id: number;
        number: string;
    }
}


export interface RolesView {
    success?: boolean;
    data: {
        data: RolesViewData[];
    };
}

export interface RolesViewData {

}

export interface RolePermissionView {
    success?: boolean;
    data: {
        data: RolePermissionData[];
    };
}
export interface RolePermissionData {
    can_create: boolean;
    can_delete: boolean;
    can_read: boolean;
    can_update: boolean;
    id?: number;
    role_id?: number;
    subject_id?: number;
}