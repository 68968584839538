import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, shareReplay } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { Router } from '@angular/router';
import { User } from '../models';
import { SettingsService } from './settings.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<currentUser> = new BehaviorSubject(null);
    public currentUser$: Observable<currentUser> = this.currentUserSubject.pipe(
        shareReplay({ refCount: true, bufferSize: 1 })
    );

    constructor(
        private router: Router,
        private http: HttpClient,
        private settingsService: SettingsService
    ) {
        this.currentUserSubject.next(JSON.parse(localStorage.getItem('currentUser')))
    }

    public get currentUserValue() {
        return this.currentUserSubject.value?.user;
    }

    public set currentUserValue(user: User) {
        let currentUser = this.currentUserSubject.value
        currentUser.user = user

        localStorage.setItem('currentUser', JSON.stringify(currentUser));
        this.currentUserSubject.next(currentUser);
    }

    public get currentUserToken() {
        return this.currentUserSubject.value?.access_token; // JSON.parse(localStorage.getItem('currentUser'));
    }

    public get currentUserEmailVerified() {
        return !!this.currentUserSubject.value?.user.email_verified_at;
    }

    public set currentUserStart(user: User) {
        let currentUser = this.currentUserSubject.value
        // currentUser.user = user
        currentUser.user.start_lon = user.start_lon
        currentUser.user.start_lat = user.start_lat
        currentUser.user.start_zoom = user.start_zoom

        localStorage.setItem('currentUser', JSON.stringify(currentUser));
    }

    // public demoLogin() {
    //     let customUser = Object.assign({}, new User());
    //     customUser.email_verified_at = "2023-06-28T13:26:10.000000Z";
    //     let user = {
    //         access_token: '...',
    //         message: '...',
    //         user: customUser,
    //     }
    //     localStorage.setItem('currentUser', JSON.stringify(user));
    //                 this.currentUserSubject.next(user);
    //                 this.router.navigate(['/']);
    // }

    login(email: string, password: string) {
        return this.http
            .post<any>(`${environment.apiUrl}/auth/login`, { email, password })
            .pipe(
                // tap(data => console.log('login',data)),
                tap((user) => {
                    // console.log('login',user)
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.settingsService.initSettings()
                    localStorage.setItem('settings', JSON.stringify({
                        backgroundMap: 'OSM',
                        layers: []
                    }));
                    this.currentUserSubject.next(user);
                    // return user;
                })
            );
    }

    verifyEmail(id: number, hash: string) {
        return this.http.get(`${environment.apiUrl}/auth/email/verify/${id}/${hash}`)
            .pipe(
                tap((user) => {
                    // console.log('login',user)
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(<currentUser>user);
                })
            );
    }


    refresh() {
        return this.http
            .post<any>(
                `${environment.apiUrl}/auth/refresh`,
                {},
                {
                    headers: {
                        Authorization:
                            'Bearer ' + this.currentUserToken,
                    },
                }
            )
            .pipe(
                map((user) => {
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                    return user;
                })
            );
    }

    me() {
        return this.http.post<User>(`${environment.apiUrl}/auth/me`, {});
    }

    logout() {
        // this.refresh().subscribe(() => {
        // });
        // const accessToken = this.currentUserToken.access_token;
        // console.log('logout')

        // this.router.navigate(['/', 'login']);
        if (!this.currentUserSubject.value) {

            // debugger
            return of(null)
        }

        return this.http.post<any>(
            `${environment.apiUrl}/auth/logout`,
            {}
            // , {
            //     // headers: { "Authorization": "Bearer " + accessToken }
            // }
        ).pipe(
            finalize(() => {
                this.router.navigate(['/', 'login'])
                localStorage.removeItem('currentUser');
                this.settingsService.clearSettings()
                this.currentUserSubject.next(null);
            })
        );
    }
}

export interface currentUser {
    access_token: string;
    message: string;
    user: User;
}
