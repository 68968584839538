import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, first, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { AlertService } from "@shared/services";
import { ImusDestroyService } from '../services/destroy.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private readonly _destroy$: ImusDestroyService,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                // this.authenticationService.logout().subscribe();
                // location.reload();
            }
            if (err.status === 403) {
                // auto logout if 403 response returned from api
                // this.authenticationService.logout().pipe(first(),takeUntil(this._destroy$)).subscribe();
                // location.reload();
            }
            const errorMessage = err.error?.message || err.statusText || err.error?.status; //err.error?.status || err.statusText;
            this.alertService.warning(errorMessage, undefined, 7500);

            if (err.status === 409) {
                // Перенаправляем на
                // console.log(err)
                if (err.error.status == 'Email already registered') {
                    const error = new Error(errorMessage);
                    error.cause = err
                    throw error
                }
            }


            // throw new Error(error)

            return throwError(() => err);

            // return throwError(err);

            // return throwError(() => new Error(error, { cause: {
            //     massage:  err.error?.status,
            //     status: err.status
            // } }));
        }))
    }
}
