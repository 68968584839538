export const OPERATIONS_STATE = new Map<number, {
    on: string;
    off: string;
}>([
    [1, { on: 'Действующий', off: 'Недействующий'}],
    [2, { on: 'Включен', off: 'Отключен'}],
    [3, { on: 'Включен', off: 'Отключен'}],
    [4, { on: 'Включен', off: 'Отключен'}],
    [5, { on: 'Включен', off: 'Отключен'}],
    [6, { on: 'Действующий', off: 'Недействующий'}],
    [7, { on: 'Действующий', off: 'Недействующий'}],
    [8, { on: 'Действующий', off: 'Недействующий'}]
]);
