import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { DeviceTypes } from '../shared/models/device';
import { BehaviorSubject, concatMap, finalize, map, Observable, of, shareReplay, startWith, switchMap, tap } from 'rxjs';
import { HttpParamsUtility, HttpSettings } from '../shared/utilities/http-utility';
@Injectable({
  providedIn: 'root'
})
export class DevicesRouteService {

  private readonly _loadingList$ = new BehaviorSubject(false);
  public readonly loadingList$: Observable<boolean> = this._loadingList$;
  private readonly changed$ = new EventEmitter<void>();
  public readonly devicesList$ = this.changed$.pipe(
      startWith(''),
      switchMap(() => this.loadDeviceList()),
      shareReplay(1)
  );

  constructor(protected httpClient: HttpClient) { }

  updateDeviceList(): void {
      this.changed$.emit();
  }
  checkAuth(){
    this.httpClient.post(`${HttpSettings.apiHost}/auth/me`, JSON.stringify ({ }))
    .subscribe((res:any)=>{ console.log(res);});
  }

  loadDeviceList(): Observable<DeviceTypes.IDevicesResponse> {
    this._loadingList$.next(true);
    return of(this._loadingList$.next(true)).pipe(
        switchMap(() => this.httpClient.get<DeviceTypes.IDevicesResponse>(`${HttpSettings.apiHost}/device`).pipe(
            finalize(() => this._loadingList$.next(false))
        )),
        map((resp) => {
            const result: DeviceTypes.IDevicesResponse = resp;
            result.devices = result.devices.map((d) => {
                if(d.created_at)
                    d.created_at = new Date(d.created_at);
                if(d.updated_at)
                    d.updated_at = new Date(d.updated_at);
                return d;
            })
            return result;
        })
    )
  }

  loadDeviceById(id: number){
    return this.httpClient.get<DeviceTypes.IDevice>(`${HttpSettings.apiHost}/device/${id}`);
  }

  loadDeviceTypeList(){
    return this.httpClient.get<DeviceTypes.IDeviceType[]>(`${HttpSettings.apiHost}/type`);
  }

  addDevice(request: DeviceTypes.IAddEditDeviceRequest){
    return this.httpClient.post(`${HttpSettings.apiHost}/device`, request).pipe(
        tap(() => this.changed$.emit())
    );
  }

  editDevice(request: DeviceTypes.IAddEditDeviceRequest, id: number){
    return this.httpClient.put<DeviceTypes.IEditDeviceResponse>(`${HttpSettings.apiHost}/device/${id}`, request).pipe(
        tap(() => this.changed$.emit())
    );
  }

  deleteDevice(id: number){
    return this.httpClient.delete(`${HttpSettings.apiHost}/device/${id}`).pipe(
        tap(() => this.changed$.emit())
    );
  }
}
