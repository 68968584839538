import { Component, Host, Input, OnInit, forwardRef } from '@angular/core';
import { LayerVectorComponent } from '../layers/layervector.component';
import { SourceComponent } from './source.component';
import { Collection, Feature } from 'ol';
import { Vector as VectorSource } from 'ol/source';
import { GeoJSON } from 'ol/format';
import { ProjectionLike } from 'ol/proj';
import { Geometry } from 'ol/geom';

@Component({
  selector: 'aol-source-geojson',
  template: ` <ng-content></ng-content> `,
  providers: [{ provide: SourceComponent, useExisting: forwardRef(() => SourceGeoJSONComponent) }],
})
export class SourceGeoJSONComponent extends SourceComponent implements OnInit {
  override instance: VectorSource;
  format: GeoJSON;
  features: Feature<Geometry>[] | Collection<Feature<Geometry>> | undefined;
  @Input()
  defaultDataProjection: ProjectionLike = 'EPSG:4326';
  @Input()
  featureProjection: ProjectionLike = 'EPSG:3857';
  @Input()
  geometryName: string;
  @Input()
  url: string;
  @Input()
  data: string;

  constructor(@Host() layer: LayerVectorComponent) {
    super(layer);
  }

  ngOnInit() {
    if (this.url) this.format = new GeoJSON(this);
    if (this.data) this.features = new GeoJSON(this).readFeatures(JSON.parse(this.data));
    this.instance = new VectorSource(this);
    this.host.instance.setSource(this.instance);
  }
}
