import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, of, takeUntil, tap } from 'rxjs';
import { ButtonComponentSettings } from 'src/app/shared/components/button/button.component';
import { InputComponentSettings } from 'src/app/shared/components/input/input.component';
import { DeviceService } from 'src/app/shared/services/device.service';
import { DeviceTypes } from '@app/shared/models/device';
import { AddEditDeviceDialogComponent } from '../add-edit-device-dialog/add-edit-device-dialog.component';
import { PageEvent } from '@angular/material/paginator';
import { PaginatorComponent } from 'src/app/shared/components/paginator/paginator.component';
import { MatSort } from '@angular/material/sort';
import { DeviceMeasurementsComponent } from '../../measurement/device-measurements/device-measurements.component';
import { ImusDestroyService } from '@app/shared/services/destroy.service';
import { MapService } from '@app/modules/map/services';
import { TooltipComponent } from '@angular/material/tooltip';

@Component({
    selector: 'app-devices-grid-dialog',
    templateUrl: './devices-grid-dialog.component.html',
    styleUrls: ['./devices-grid-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ImusDestroyService],
})
export class DevicesGridDialogComponent implements OnInit {
    buttonType = ButtonComponentSettings.ButtonType;
    inputType = InputComponentSettings.InputType;
    updateMask = 'd [0-9]';

    loadingDeviceList$ = this.deviceService.loadingDeviceList$;

    displayedColumns: string[] = [
        'name',
        'controlInfo',
        'controlLocation',
        'type',
        'units',
        'measure',
        'min',
        'max',
        'token',
        'updated_at',
        'created_at',
        'controlEdit',
        'controlRemove',
    ];

    deviceData: DeviceTypes.IDevicesResponse;
    deviceList$ = new BehaviorSubject<DeviceTypes.IDevice[]>([]);

    get ifDialogOpeningAllowed() {
        return (
            (this.dialog === undefined || this.dialog.closed) &&
            !this.deleteDialogOpened
        );
    }
    private dialog;
    private deleteDialogOpened = false;

    pageIndex = 0;
    pageSize = 5;
    pageSizeOptions = [5, 10, 25];
    @ViewChild(PaginatorComponent) paginator: PaginatorComponent;
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private deviceService: DeviceService,
        private matDialog: MatDialog,
        public dialogRef: MatDialogRef<DevicesGridDialogComponent>,
        private changeDetector: ChangeDetectorRef,
        private readonly destroy$: ImusDestroyService,
        private mapService: MapService
    ) {}

    ngOnInit(): void {
        this.initData();
        this.init();
    }

    private init() {
        this.deviceService.deviceList$
            .pipe(
                // tap(data => console.log(data)),
                takeUntil(this.destroy$),
            )
            .subscribe((data) => {
                this.deviceData = data;
                this.sortingPagingDeviceList();
            });
    }

    private initData() {
        this.deviceService.loadDeviceList();
    }

    public refreshData() {
        this.initData();
    }

    onAddDevicesClick() {
        this.deviceService.editableDevice.next({});
        this.deviceService.gridDialogRolledUp = true;
        this.dialogRef.close();
        // this.matDialog.closeAll();
    }

    // onEditDeviceClickDeprecated(device: DeviceTypes.IDevice) {
    //     this.dialog = this.matDialog
    //         .open(AddEditDeviceDialogComponent, { data: { device: device } })
    //         .afterClosed()
    //         .subscribe(() => this.changeDetector.markForCheck());
    // }

    onEditDeviceClick(device: DeviceTypes.IDevice) {
        console.log('DevicesGridDialogComponent onEditDeviceClick', device);
        this.deviceService.editableDevice.next(device);
        this.deviceService.gridDialogRolledUp = true;
        this.dialogRef.close();
        this.matDialog.closeAll();
}

    onDeleteDeviceClick(device: DeviceTypes.IDevice) {
        this.deleteDialogOpened = true;
        this.changeDetector.markForCheck();
        this.deviceService.deleteDevice(device).subscribe((result) => {
            if (result !== undefined) {
                this.deleteDialogOpened = false;
                this.changeDetector.markForCheck();
            }
        });
    }

    onMeasurementClick(device: DeviceTypes.IDevice) {
        this.dialog = this.matDialog
            .open(DeviceMeasurementsComponent, { data: { device: device } })
            .afterClosed()
            .subscribe(() => this.changeDetector.markForCheck());
    }

    onPageEvent() {
        this.sortingPagingDeviceList();
    }

    sortingPagingDeviceList() {
        if (this.paginator && this.sort && this.deviceData) {
            let gridData =
                this.sort.direction == 'asc'
                    ? this.deviceData.devices.sort((a, b) =>
                          a[this.sort.active] < b[this.sort.active] ? -1 : 1
                      )
                    : this.deviceData.devices.sort((a, b) =>
                          a[this.sort.active] > b[this.sort.active] ? -1 : 1
                      );
            const startPoint =
                this.paginator.pageIndex * this.paginator.pageSize;
            gridData = gridData.slice(
                startPoint,
                startPoint + this.paginator.pageSize
            );
            this.deviceList$.next(gridData);
        }
    }

    isLocationClickAllowed(item) {
        return (item.node || item.plot);
    }

    onLocationClick(item?: DeviceTypes.IDevice) {
        this.mapService.zoomToDevice(item);
        this.matDialog.closeAll();
    }

    @ViewChild('tooltipLocation') public tooltipLocation: TooltipComponent | any;
    @ViewChild('tooltipInfo') public tooltipInfo: TooltipComponent | any;
    @ViewChild('tooltipEdit') public tooltipEdit: TooltipComponent | any;
    @ViewChild('tooltipRemove') public tooltipRemove: TooltipComponent | any;

    public helpActive: boolean = false;
    tooltipsShowAll() {
        this.helpActive = true;
        this.tooltipInfo.show();
        this.tooltipLocation.show(400);
        this.tooltipEdit.show(1000);
        // this.tooltipRemove.show(800);
        setTimeout(() => {
            this.tooltipInfo.hide();
            this.tooltipLocation.hide(100);
            this.tooltipEdit.hide(200);
            // this.tooltipRemove.hide(300);
            this.helpActive = false;
        }, 3000);
    }

}
