import {
    ChangeDetectionStrategy,
    Component,
    inject,
    Input,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {takeUntil} from "rxjs";

import {SharedModule} from "@shared/shared.module";
import {AlertService, UserService} from "@shared/services";
import {ImusDestroyService} from "@services/destroy.service";
import {UserAccountsInfo} from "@shared/models";
import {ButtonComponentSettings} from "@shared/components/button/button.component";
import { passwordMatchValidator } from '@app/shared/validators/password.validator';

@Component({
    selector: 'credentials',
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule, ReactiveFormsModule, SharedModule],
    templateUrl: './credentials.component.html',
    styleUrls: ['./credentials.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class CredentialsComponent implements OnInit {
    private fb = inject(FormBuilder);
    private userService = inject(UserService);
    private destroy = inject(ImusDestroyService);
    private alertService = inject(AlertService);
    public readonly buttonType = ButtonComponentSettings.ButtonType;

    @Input() userId: string;
    @Input() userAccountsInfo: UserAccountsInfo;
    @Input() access: string;

    userForm: FormGroup;
    passwordForm: FormGroup;
    hideNew = true;

    ngOnInit() {
        this.initForms();
        this.getUserInfo(this.userId);
    }

    initForms(): void {
        this.userForm = this.fb.group({
            name: [null, Validators.required],
            email: [null, Validators.required],
            phone: null,
        });

        this.passwordForm = this.fb.group({
            password: [null, Validators.required],
            confirmPassword: [null, Validators.required],
            }, 
            {
            validators: passwordMatchValidator
        });
    }

    submitUserForm(): void {
        this.userService.putUserInfo(this.userId, this.userForm.value)
            .pipe(takeUntil(this.destroy))
            .subscribe({
                next: data => {
                    this.alertService.openSnackbar('Данные успешно обновлены')
                },
                error: error => {
                    this.alertService.error(error);
                }
            })
    }

    submitPasswordForm(): void {
        this.userService.putUserInfo(this.userId, {
            password: this.passwordForm.value.password
            })
            .pipe(takeUntil(this.destroy))
            .subscribe({
                next: data => {
                    this.alertService.openSnackbar('Пароль успешно изменен')
                },
                error: error => {
                    this.alertService.error(error);
                }
            })
    }

    getUserInfo(id: string): void {
        this.userService.getUserInfo(id)
            .pipe(takeUntil(this.destroy))
            .subscribe(user => {
                this.userForm.patchValue({
                    name: user.name,
                    email: user.email,
                    phone: user.phone
                })
            })
    }
}
